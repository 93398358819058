import {FC, useEffect, useState} from 'react';
import {ITaskSmsComponent} from "../../interfaces/Components/ITaskSmsComponent";
import {useSmsSend} from "../../hooks/useSms";

export const TaskSmsComponent: FC<ITaskSmsComponent> = (props: ITaskSmsComponent) => {
    const SmsSendHook = useSmsSend(),
        maxSmsLength = 10,
        [sms, setSms] = useState<string>(''),
        [isModalVisible, setIsModalVisible] = useState<Boolean>(props.isModalVisible),
        changeSms = (val: string) => {
            setSms(val);
        },
        close = () => {
            props.changeModalVisible(false);
        },
        sendSms = () => {
            SmsSendHook.data({
                userId: props.userId,
                message: sms
            });
            props.changeModalVisible(false);
        };

    useEffect(() => {
        setIsModalVisible(props.isModalVisible);
    }, [props.isModalVisible]);

    return isModalVisible && (
        <div className="white-opacity white-opacity--full white-opacity--transition-enter-done">
            <button type="button" className="white-opacity__close" onClick={() => close()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g transform="translate(-16 -40)" opacity="0.38">
                        <g transform="translate(16 40)">
                            <rect width="24" height="24" fill="none"/>
                            <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"/>
                        </g>
                    </g>
                </svg>
            </button>
            <div className="white-opacity__wrapper">
                <div className="white-opacity__title">Wyślij SMS do</div>
                <div className="white-opacity__phone">{props.phone}</div>
                <div className="white-opacity__field">
                    <div className={`field${sms.length > 0 ? ' field--tall' : ''}`}>
                        <textarea placeholder="Wpisz treść SMS" className="field__textarea" onChange={(event) => changeSms(event.target.value)}/>
                    </div>
                </div>
                <div className="white-opacity__action">
                    <div className="actions">
                        <div className="actions__left">
                            <div className="comment-editor__counter">{sms.length} / {maxSmsLength}</div>
                        </div>
                        <div className="actions__right">
                            <button className={`blue-btn${!sms.length || sms.length > maxSmsLength ? ' blue-btn--disabled' : ''}`} type="button" disabled={!sms.length || sms.length > maxSmsLength}
                                    onClick={() => sendSms()}>
                                <span className="blue-btn__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.009" viewBox="0 0 14 14.009">
                                        <path d="M15.952,6.058a7.006,7.006,0,1,0,1.821,6.7H15.952a5.251,5.251,0,1,1-4.947-7A5.179,5.179,0,0,1,14.7,7.31l-2.819,2.819H18.01V4Z"
                                              transform="translate(-4.01 -4)"
                                              fill="#fff"/>
                                    </svg>
                                </span>
                                Wyślij
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
