import {useQuery, useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {deleteJSON, getJSON} from "../api/http";
import {IPagination} from "../core/types";
import {ITask} from "../interfaces/ITask";
import {restructureDate} from "../shared/utils";
import {taskStatuses} from "../staticData/taskStatuses";
import {useContext} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const getTasks = async (stringParams: string) =>
    getJSON(
        [endpoints.task.list, stringParams && "?", stringParams].join("")
    );

interface Props extends IPagination {
    address?: String;
    status: Number;
    userid?: Number;
    queryWithUserId?: Boolean;
}

const getFilterStatuses = ((status: any) => {
    let ret = '';
    taskStatuses.map((taskStatus: any) => {
        if (taskStatus.id == status) {
            Object.keys(taskStatus['filter']).map((filterName) => {
                ret += `&${filterName}`;
                if (typeof taskStatus['filter'][filterName] == 'object') {
                    Object.keys(taskStatus['filter'][filterName]).map((filterType) => {
                        ret += `[${filterType}]=`;
                        if (typeof taskStatus['filter'][filterName][filterType] == 'object') {
                            ret += taskStatus['filter'][filterName][filterType].join(',');
                        }
                    });
                } else {
                    ret += `=${taskStatus['filter'][filterName]}`;
                }
            });
            ret = ret.substring(1);
        }
    });
    return ret;
});

export const useTasks = (props: Props) => {
    const address = props.address ? 'address=' + props.address : '',
        admin = props.userid && props.queryWithUserId ? 'Admins=' + props.userid : '',
        limit = props._limit ? '_limit=' + props._limit : '',
        order = props._order ? '_order=' + props._order : '',
        orderBy = props._orderBy ? '_orderby=' + props._orderBy : '',
        page = props._page ? '_page=' + props._page : '',
        status = props.status,
        statusQuery = getFilterStatuses(props.status),
        queryClient = useQueryClient(),
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `tasks${props.queryWithUserId ? '_' + props.userid : ''}_${status}_${props._page}_${props.address}`,
            () => getTasks([address, admin, page, statusQuery, limit, order, orderBy].filter(Boolean).join('&')).then((tasks: any) => {
                if (tasks && tasks.data && tasks.data.list) {
                    tasks.data.list.map((task: ITask) => {
                        task.creationdateString = restructureDate(task.creationdate);
                    });
                }
                return tasks;
            }),
            {
                enabled: props.userid != null || !props.queryWithUserId,
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await queryClient.invalidateQueries();
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                staleTime: Infinity,
            }
        );

    return {
        isLoading,
        data: data?.data,
        error
    };
};