export const LocalStorageService = () => {
    const defaultLastError = 'Login, bądź hasło niepoprawne.';

    const setDefaultLastError = (suffix: string = '') => {
        return localStorage.setItem('_lastError' + (suffix ? '_' + suffix : ''), defaultLastError);
    };

    const setLastError = (error: string, suffix: string = '') => {
        localStorage.setItem('_lastError' + (suffix ? '_' + suffix : ''), error);
    };

    const setToken = (token: string, suffix: string = '') => {
        localStorage.setItem('_services' + (suffix ? '_' + suffix : ''), token);
    };

    const setStatusLS = (status: number, suffix: string = '') => {
        localStorage.setItem('_status' + (suffix ? '_' + suffix : ''), status.toString());
    };

    const setMenuLS = (menu: number, suffix: string = '') => {
        localStorage.setItem('_menu' + (suffix ? '_' + suffix : ''), menu.toString());
    };

    const getLastError = (suffix: string = '') => {
        return localStorage.getItem('_lastError' + (suffix ? '_' + suffix : '')) || defaultLastError;
    };

    const getToken = (suffix: string = '') => {
        return localStorage.getItem('_services' + (suffix ? '_' + suffix : ''));
    };

    const getShowError = (suffix: string = '') => {
        return getLastError(suffix) !== 'Login, bądź hasło niepoprawne.';
    };

    const getStatusLS = (suffix: string = '') => {
        return Number(localStorage.getItem('_status' + (suffix ? '_' + suffix : '')));
    };

    const getMenuLS = (suffix: string = '') => {
        return Number(localStorage.getItem('_menu' + (suffix ? '_' + suffix : '')));
    };

    const clearToken = (suffix: string = '') => {
        localStorage.removeItem('_services' + (suffix ? '_' + suffix : ''));
    };

    return {setDefaultLastError, setLastError, setToken, getToken, clearToken, setStatusLS, getLastError, getShowError, getStatusLS, setMenuLS, getMenuLS};
};
