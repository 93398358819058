import {useMutation, useQuery, useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {deleteJSON, getJSON, putJSON} from "../api/http";
import {useContext, useState} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const getVat = async (stringParams: string) =>
    getJSON(
        [endpoints.vat.list, stringParams && "?", stringParams].join("")
    );

interface Props {
    active?: Boolean;
}

export const useVat = (props: Props) => {
    const queryClient = useQueryClient(),
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `vat_${props.active}`,
            () => getVat([props.active].filter(Boolean).join('&')),
            {
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await queryClient.invalidateQueries();
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                retry: false,
                staleTime: Infinity,
                suspense: true
            }
        );

    return {
        isLoading,
        data: data?.data,
        error,
    };
};

const changeVat = async (data: any) => {
    return putJSON(endpoints.task.record.replaceAll('{id}', data.id.toString()), {
        history_entry: 'zlecenie odpłatne',
        netto: data.netto,
        payable: 1,
        vatid: data.vat
    });
}

export const useChangeVat = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            changeVat,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    let message = '';
                    Object.keys(error?.data.errors).map((errorMsg) => {
                        message += error?.data.errors[errorMsg] + '\n';
                    });
                    alert(message);
                    setErrors(Object.values(message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};

const unsetVat = async (recordId: Number) =>
    putJSON(endpoints.task.record.replaceAll('{id}', recordId.toString()),
        {
            payable: 0,
            history_entry: 'zlecenie niepłatne'
        }
    );

export const useUnsetVat = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            unsetVat,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};
