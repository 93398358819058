import {IMenuItem} from "../interfaces/IMenuItem";

export const menuItems: Array<IMenuItem> = [
    {
        id: 1,
        name: 'Twoje zadania',
        addUserId: true
    }, {
        id: 2,
        name: 'Wszystkie zadania',
        addUserId: false
    }, {
        id: 3,
        name: 'Aktywne zadania',
        addUserId: false,
        newStatus: [1, 2, 3, 4]
    }, {
        id: 4,
        name: 'Nieaktywne zadania',
        addUserId: false,
        newStatus: [5]
    }, {
        id: 5,
        name: 'Zadania bez zamkniętych',
        addUserId: false,
        newStatus: [1, 2, 3, 4, 5]
    }, {
        id: 6,
        name: 'Zamknięte zadania',
        addUserId: false,
        newStatus: [6, 7]
    }
]