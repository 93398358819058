import {useMutation, useQuery, useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {deleteJSON, extendedPostJSON, getJSON, putJSON} from "../api/http";
import {useContext, useState} from "react";
import {IPagination} from "../core/types";
import {ITaskHistory} from "../interfaces/ITaskHistory";
import {restructureDate} from "../shared/utils";
import {taskStatuses} from "../staticData/taskStatuses";
import {getAdmin} from "./useAdmins";
import {AxiosResponse} from "axios";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

export const getTaskHistory = async (props: Props) => {
    const damageId = props.damageId ? 'damageid=' + props.damageId : '',
        limit = props._limit ? '_limit=' + props._limit : '',
        order = props._order ? '_order=' + props._order : '',
        orderBy = props._orderBy ? '_orderby=' + props._orderBy : '',
        page = props._page ? '_page=' + props._page : '';
    return getJSON(endpoints.damagesHistory.list + '?' + [page, limit, order, orderBy, damageId].filter(Boolean).join('&')).then((ret) => getTaskStatus(ret));
}

interface Props extends IPagination {
    damageId?: Number;
}

const getTaskStatus = async (ret: AxiosResponse<any, any>) => {
    if (ret.data && ret.data.list) {
        const users: Record<number, object> = {};
        await Promise.all(await ret.data.list.map(async (taskHistory: ITaskHistory) => {
                taskStatuses.map((taskStatus) => {
                    if (taskStatus.id == taskHistory.status) {
                        taskHistory.statusString = taskStatus.name;
                    }
                });
                taskHistory.creationdateString = restructureDate(taskHistory.creationdate);
                if (!users[taskHistory.creatorid]) {
                    await getAdmin(taskHistory.creatorid).then(async (userData) => {
                        taskHistory.user = userData.data;
                        users[taskHistory.creatorid] = userData.data;
                    });
                    // await getAdmin(taskHistory.creatorid).then(async (userData) => {
                    //     taskHistory.user = userData.data;
                    //     users[taskHistory.creatorid] = userData.data;
                    // });
                    // console.log(taskHistory.creatorid, {...users});
                }
            })
        );
    }
    return ret;
};

export const useTaskHistory = (props: Props) => {
    const queryClient = useQueryClient(),
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error, refetch} = useQuery(
        `taskHistory_${props.damageId}_${props._page}`,
        () => getTaskHistory(props),
        {
            enabled: props.damageId != null,
            onError: async () => {
                setLastError('Brak uprawnień');
                await queryClient.invalidateQueries();
                await deleteJSON(endpoints.auth);
                dispatch({type: 'NOT_LOGGED'});
            },
            retry: false,
            staleTime: Infinity,
            suspense: true
        }
    );

    return {
        isLoading,
        data: data?.data,
        error,
        refetch
    };
};

const getAddTaskHistory = (data: any) =>
    extendedPostJSON(endpoints.damagesHistory.add, data);

export const useAddTaskHistory = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            getAddTaskHistory,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.errors || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("taskHistory");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};

const changeTaskHistory = async (data: any) =>
    putJSON(endpoints.damagesHistory.change.replaceAll('{id}', data.id.toString()), {
        description: data.description
    });

export const useChangeTaskHistory = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            changeTaskHistory,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    let message = '';
                    Object.keys(error?.data.errors).map((errorMsg) => {
                        message += error?.data.errors[errorMsg] + '\n';
                    });
                    alert(message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("taskHistory");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};