import {useQuery, useQueryClient} from "react-query";
import {deleteJSON, getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {useContext} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const getFirm = async () => getJSON(endpoints.firm);

export const useFirm = () => {
    const queryClient = useQueryClient(),
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery("firm", getFirm, {
        onError: async () => {
            setLastError('Brak uprawnień');
            await queryClient.invalidateQueries();
            await deleteJSON(endpoints.auth);
            dispatch({type: 'NOT_LOGGED'});
        },
        retry: false,
        staleTime: Infinity,
        suspense: true
    });

    return {
        isLoading,
        data: data?.data || {},
        error,
    };
};
