import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useAddTaskHistory, useChangeTaskHistory, useTaskHistory} from "../../hooks/useTaskHistory";
import {IComment, ITaskCommentsComponent} from "../../interfaces/Components/ITaskCommentsComponent";

export const TaskCommentsComponent: FC<ITaskCommentsComponent> = (props: ITaskCommentsComponent) => {
    const
        addTaskHistoryHook = useAddTaskHistory(),
        changeTaskHistoryHook = useChangeTaskHistory(),
        maxDescriptionLength = 8000,
        taskHistory = useTaskHistory({_order: 'desc', _orderBy: 'id', damageId: props.taskId}),
        [description, setDescription] = useState<string>(''),
        [showEditor, setShowEditor] = useState<Array<IComment>>(),
        addTaskHistory = () => {
            addTaskHistoryHook.data({
                description: description,
                damageid: props.taskId
            });
            setDescription('');
        },
        changeHistoryDescription = (historyIndex: number, description: string) => {
            if (!showEditor) {
                return;
            }
            const showHelper = showEditor.map((history, index) => {
                if (index == historyIndex) {
                    history['description'] = description;
                }
                return history;
            });
            setShowEditor(showHelper);
        },
        changeHistory = (historyIndex: number) => {
            if (!showEditor) {
                return;
            }
            changeTaskHistoryHook.data({
                id: showEditor[historyIndex]['id'],
                description: showEditor[historyIndex]['description']
            });
            toggleShowEditor(historyIndex);
            taskHistory.refetch();
        },
        commentChange = (val: string) => {
            setDescription(val);
        },
        toggleShowEditor = (historyIndex: any) => {
            if (!showEditor) {
                return;
            }
            const showHelper = showEditor.map((history, index) => {
                if (index == historyIndex) {
                    history['isEditorShow'] = !history['isEditorShow'];
                }
                return history;
            });
            setShowEditor(showHelper);
        };

    useEffect(() => {
        taskHistory.refetch();
    }, [addTaskHistoryHook.isSuccess, addTaskHistoryHook.isError]);

    useEffect(() => {
        if (taskHistory.data && taskHistory.data.list) {
            let showEditor: Array<IComment> = [];
            taskHistory.data.list.map((history: any) => {
                showEditor.push({
                    id: history.id,
                    isEditorShow: false,
                    description: history.description
                })
            });
            setShowEditor(showEditor);
        }
    }, [taskHistory.data]);

    return (
        <section className="rounded-card">
            <div className="comments">
                <div className="task-info-headline">
                    Opis zlecenia
                </div>
                {props.status < 6 && (
                    <div className="comments__add">
                        <div className="comment-editor">
                            <div className="comment-editor__field">
                                <div className={`field${description.length > 0 ? ' field--tall' : ''}`}>
                                    <textarea placeholder="Utwórz nowy wpis" className="field__textarea" value={description}
                                              onInput={(event: ChangeEvent<HTMLTextAreaElement>) => commentChange(event.target.value)}/>
                                </div>
                            </div>
                            <div className="actions">
                                <div className="actions__left">
                                    <div className="comment-editor__counter">
                                        {description.length} / {maxDescriptionLength}
                                    </div>
                                </div>
                                <div className="actions__right">
                                    <button className={`blue-btn${!description.length || description.length > maxDescriptionLength ? ' blue-btn--disabled' : ''}`} type="button"
                                            disabled={!description.length || description.length > maxDescriptionLength} onClick={() => addTaskHistory()}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.009" viewBox="0 0 14 14.009">
                                                <path d="M15.952,6.058a7.006,7.006,0,1,0,1.821,6.7H15.952a5.251,5.251,0,1,1-4.947-7A5.179,5.179,0,0,1,14.7,7.31l-2.819,2.819H18.01V4Z"
                                                      transform="translate(-4.01 -4)" fill="#fff"/>
                                            </svg>
                                        </span>
                                        Dodaj wpis
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="comments__list">
                    {taskHistory && taskHistory.data && taskHistory.data.list && taskHistory.data.list.map((history: any, historyIndex: number) => (
                        <div className="comment" key={history.id}>
                            <div className="comment__status">
                                {history.statusString}
                            </div>
                            <div className="comment__desc">
                                <div className="comment__title">
                                    {history.creationdateString} - {history.user?.name}
                                </div>
                                {(!showEditor || !showEditor[historyIndex] || !showEditor[historyIndex]['isEditorShow'] || showEditor[historyIndex]['isEditorShow'] == null) && (
                                    <div className="comment__msg">
                                        <p>
                                            {history.description}
                                        </p>
                                    </div>
                                )}
                                {showEditor && showEditor[historyIndex] && showEditor[historyIndex]['isEditorShow'] && (
                                    <div className="comment-editor">
                                        <div className="comment-editor__field">
                                            <div className="field field--tall">
                                                    <textarea className="field__textarea" value={showEditor[historyIndex]['description']}
                                                              onChange={(event) => changeHistoryDescription(historyIndex, event.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <div className="actions__center">
                                                <div className="comment-editor__counter">
                                                    {showEditor[historyIndex]['description'].length} / {maxDescriptionLength}
                                                </div>
                                            </div>
                                            <div className="actions__center">
                                                <button className="blue-btn" type="button" onClick={() => changeHistory(historyIndex)}>
                                                        <span className="blue-btn__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.009" viewBox="0 0 14 14.009">
                                                                <path d="M15.952,6.058a7.006,7.006,0,1,0,1.821,6.7H15.952a5.251,5.251,0,1,1-4.947-7A5.179,5.179,0,0,1,14.7,7.31l-2.819,2.819H18.01V4Z"
                                                                      transform="translate(-4.01 -4)" fill="#fff"/>
                                                            </svg>
                                                        </span>
                                                    Uaktualnij wpis
                                                </button>
                                                <button className="blue-btn" type="button" onClick={() => toggleShowEditor(historyIndex)}>
                                                    Anuluj
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {(!showEditor || !showEditor[historyIndex] || !showEditor[historyIndex]['isEditorShow'] || showEditor[historyIndex]['isEditorShow'] == null) && props.status < 6 && (
                                <div className="comment__actions">
                                    <button className="edit-btn" type="button" onClick={() => toggleShowEditor(historyIndex)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.32" height="18.231" viewBox="0 0 18.32 18.231">
                                            <g transform="translate(-0.001 -1.289)">
                                                <path
                                                    d="M11.392,4.331l3.727,3.727L5.686,17.491,1.961,13.764Zm6.554-.9L16.285,1.77a1.649,1.649,0,0,0-2.33,0L12.363,3.362,16.09,7.089l1.857-1.857A1.27,1.27,0,0,0,17.947,3.432ZM.011,19a.424.424,0,0,0,.513.5L4.677,18.5.952,14.773Z"
                                                    transform="translate(0)" fill="#3e3e3e"/>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
