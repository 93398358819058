import {useMutation, useQuery, useQueryClient} from "react-query";
import {axiosInstance, deleteJSON, extendedPostJSON, getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {useContext, useState} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const loginUser = (data: any) => extendedPostJSON(endpoints.auth, data);

export const useAuth = () => {
    const queryClient = useQueryClient(),
        {dispatch} = useContext(LoginStateContext),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isError} = useMutation(loginUser, {
            onSuccess: ({data}) => {
                axiosInstance.defaults.headers.common["auth"] = `Bearer ${data}`;
                dispatch({type: "LOGGED_IN", payload: {token: data}});
            },
            onError: (error: any) => {
                setErrors(Object.values(error?.data.errors || {}));
                dispatch({type: "NOT_LOGGED"});
            },
            onSettled: () => {
                queryClient.invalidateQueries();
            }
        });

    return {
        isError,
        errors,
        isLoading,
        loginUser: mutate,
    };
};

export const useGetLoggedUser = () => {
    const {data, isLoading, error} = useQuery(
        `user`,
        () => getJSON(endpoints.auth),
        {
            retry: false,
            staleTime: Infinity,
            suspense: true
        }
    );

    return {
        isLoading,
        data: data?.data,
        error,
    };
};
