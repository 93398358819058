import {ChangeEvent, FC, useContext, useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {LoginStateContext} from "../LoginState";
import {deleteJSON} from "../../../api/http";
import {endpoints} from "../../../api/endpoints";
import {IHeaderComponent} from "../../../interfaces/Components/IHeaderComponent";
import {LocalStorageService} from "../../../services/LocalStorageService";
import {menuItems} from '../../../staticData/menuItems';
import {IMenuItem} from "../../../interfaces/IMenuItem";

export const Header: FC<IHeaderComponent> = (props: IHeaderComponent) => {
    const {dispatch} = useContext(LoginStateContext),
        {setMenuLS, getMenuLS} = LocalStorageService(),
        [isMenuCollapsed, setIsMenuCollapsed] = useState(true),
        [menuId, setMenuId] = useState<number>(getMenuLS()),
        [siteName, setSiteName] = useState<String>(menuItems[0].name),
        [isSearchVisible, setIsSearchVisible] = useState(false),
        deleteLogout = async () => deleteJSON(endpoints.auth),
        toggleSearch = () => {
            setIsSearchVisible(!isSearchVisible);
        },
        toggleMenu = () => {
            setIsMenuCollapsed(!isMenuCollapsed);
            if (!isMenuCollapsed) {
                document.body.classList.remove('fixed');
            } else {
                document.body.classList.add('fixed');
            }
        },
        menuChange = (menuId: number) => {
            menuItems.map((menuItem) => {
                if (menuItem.id == menuId) {
                    toggleMenu();
                    setMenuId(menuId);
                    setSiteName(menuItem.name);
                    props.menuChange(menuItem.addUserId, menuItem.newStatus);
                    setMenuLS(menuId);
                }
            });
        },
        queryClient = useQueryClient(),
        handleLogout = () => {
            queryClient.invalidateQueries();
            deleteLogout();
            dispatch({type: 'NOT_LOGGED'});
        },
        clickOutside = (target: any) => {
            if (target.className && typeof target.className == 'string' && (target.className.includes('menu--transition-enter-done') || target.className.includes('container'))) {
                setIsMenuCollapsed(true);
                document.body.classList.remove('fixed');
            }
        };

    return (
        <header className="top" onClick={(event) => clickOutside(event.target)}>
            {!isMenuCollapsed && (
                <nav className="menu menu--transition-enter-done">
                    <div className="container">
                        <div className="menu__bg">
                            <div className="menu__welcome">Witaj,
                                <div className="menu__login">{props.user?.admin_name}</div>
                            </div>
                            <div className="menu__items">
                                {menuItems.map((menuItem: IMenuItem) => (
                                    <button type="button" className="menu__item" onClick={() => menuChange(menuItem.id)} key={menuItem.id}>{menuItem.name}</button>
                                ))}
                            </div>
                            <div className="menu__footer">
                                <button type="button" className="logout-btn" onClick={() => handleLogout()}>
                                    <span className="logout-btn__icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23.044" height="18.98" viewBox="0 0 23.044 18.98">
                                            <defs>
                                                <clipPath id="a">
                                                    <rect width="23.044" height="18.98" fill="#fff"/>
                                                </clipPath>
                                            </defs>
                                            <g opacity="0.5">
                                                <g transform="translate(0 0)" clipPath="url(#a)">
                                                    <path
                                                        d="M2.111,18.98H14.63a2.116,2.116,0,0,0,2.111-2.111V12.658H14.63v4.233H2.111V2.08H14.63V6.324h2.111V2.09A2.1,2.1,0,0,0,14.632,0H2.111A2.1,2.1,0,0,0,0,2.088V16.869A2.112,2.112,0,0,0,2.111,18.98"
                                                        transform="translate(0 0)" fill="#fff"/>
                                                    <path d="M18.387,13.435l4.222-4.222L18.387,4.99V8.157H7.83v2.111H18.387Z" transform="translate(0.436 0.278)" fill="#fff"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    Wyloguj
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            )}
            <div className="container">
                <div className="actions">
                    <div className="actions__left cursor-pointer" onClick={() => toggleMenu()}>
                        <button type="button" className="top__menu-btn"><span/><span/><span/></button>
                        <h1 className="top__title">
                            {siteName}
                        </h1>
                    </div>
                    <div className="actions__right">
                        {isSearchVisible && (
                            <div className="search-field search-field--transition-enter-done">
                                <input type="text" placeholder="Szukaj w zadaniach..." className="search-field__input" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    props.changeSearchInput(event.target.value)
                                }}/>
                                <button type="button" className="search-field__btn" onClick={() => toggleSearch()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g transform="translate(-16 -40)" opacity="0.38">
                                            <g transform="translate(16 40)">
                                                <rect width="24" height="24" fill="none"/>
                                                <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        )}
                        {!props.recordId && (
                            <div className="top__task-search" onClick={() => toggleSearch()}>
                                <button type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g transform="translate(-324 -418)">
                                            <path d="M24,0H0V-24H24Z" transform="translate(324 442)" fill="none" fillRule="evenodd"/>
                                            <path
                                                d="M15.509-9.485h-.794l-.276-.274a6.472,6.472,0,0,0,1.569-4.228,6.5,6.5,0,0,0-6.5-6.5,6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.474,6.474,0,0,0,4.227-1.567l.276.274v.792l5,4.994L20.5-4.483l-4.991-5Zm-6.005,0a4.5,4.5,0,0,1-4.5-4.5,4.5,4.5,0,0,1,4.5-4.5,4.5,4.5,0,0,1,4.5,4.5,4.5,4.5,0,0,1-4.5,4.5Z"
                                                transform="translate(324 441.491)" fill="#fff" fillRule="evenodd"/>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        )}
                        {props.recordId != 0 && (
                            <button className="top-close" type="button" onClick={() => props.setRecordId(0)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(-16 -40)" opacity="0.38">
                                        <g transform="translate(16 40)">
                                            <rect width="24" height="24" fill="none"/>
                                            <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};
