import {FC, useEffect, useState} from 'react';
import {ITaskCallComponent} from "../../interfaces/Components/ITaskCallComponent";

export const TaskCallComponent: FC<ITaskCallComponent> = (props: ITaskCallComponent) => {
    const [sms, setSms] = useState<string>(''),
        [isModalVisible, setIsModalVisible] = useState<Boolean>(props.isModalVisible),
        changeSms = (val: string) => {
            setSms(val);
        },
        close = () => {
            props.changeModalVisible(false);
        },
        sendSms = () => {
            console.log(props.phone, sms);
            props.changeModalVisible(false);
        };

    useEffect(() => {
        setIsModalVisible(props.isModalVisible);
    }, [props.isModalVisible]);

    return isModalVisible && (
        <div className="white-opacity white-opacity--transition-enter-done">
            <button type="button" className="white-opacity__close" onClick={() => close()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g transform="translate(-16 -40)" opacity="0.38">
                        <g transform="translate(16 40)">
                            <rect width="24" height="24" fill="none"></rect>
                            <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"/>
                        </g>
                    </g>
                </svg>
            </button>
            <div className="white-opacity__wrapper">
                <div className="white-opacity__title">
                    Zadzwoń do
                </div>
                <div className="white-opacity__phone">
                    {props.phone}
                </div>
                <a className="phone-btn" href={`tel:${props.phone}`}>
                    <span className="phone-btn__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path
                                d="M5.816,9.059a11.782,11.782,0,0,0,5.126,5.126l1.711-1.711a.773.773,0,0,1,.793-.187,8.872,8.872,0,0,0,2.777.443.78.78,0,0,1,.778.778v2.714a.78.78,0,0,1-.778.778A13.221,13.221,0,0,1,3,3.778.78.78,0,0,1,3.778,3H6.5a.78.78,0,0,1,.778.778,8.836,8.836,0,0,0,.443,2.777.781.781,0,0,1-.194.793Z"
                                transform="translate(-3 -3)" fill="#fff"/>
                        </svg>
                    </span>
                    Zadzwoń
                </a>
            </div>
        </div>
    );
};
